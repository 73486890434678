import { computed, watch, watchEffect } from 'vue'
import { productFruits } from 'product-fruits'
import { sha256 } from '@/utils/helpers/sha256'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'
import { waitForInitialApiRequestsToComplete } from '@/utils/helpers/hooks'

export function useProductFruits () {
  const userStore = useUserStore()

  const userEmail = computed<string|undefined>(() => userStore.user?.user?.email)
  const { locale } = useI18n()

  const productFruitsCode = window.configuration.PRODUCT_FRUITS_WORK_SPACE_CODE
  const shouldProductFruitsBeInitialized = productFruitsCode !== undefined

  if (shouldProductFruitsBeInitialized) {
    const productFruitsWatcher = watchEffect(async () => {
      if (userEmail.value) {
        await waitForInitialApiRequestsToComplete()
        const isAdmin = userStore.isCompanyAdmin
        const hashedEmail = await sha256(userEmail.value)

        await initProductFruits(productFruitsCode, locale.value, { role: isAdmin ? 'admin' : 'reader', username: hashedEmail })
        // destroy watcher after initializing product fruits
        productFruitsWatcher()
      }
    })
  }

  watch(locale, async (newLocale) => {
    if (shouldProductFruitsBeInitialized) {
      await destroyProductFruits()
      await waitForInitialApiRequestsToComplete()
      const isAdmin = userStore.isCompanyAdmin
      const hashedEmail = await sha256(userEmail.value as string)

      initProductFruits(userEmail.value as string, newLocale, { role: isAdmin ? 'admin' : 'reader', username: hashedEmail })
    }
  }, {
    immediate: false,
  })

  async function destroyProductFruits () {
    await window.productFruits?.services?.destroy()
  }

  async function initProductFruits (userEmail: string, locale: string, roleAndUsername: { role: string; username: string }) {
    productFruits.init(productFruitsCode as string, locale, roleAndUsername)
  }
}
