import { CompanyRole, CompanyWithContextProjects, Role, User, UserWithContext } from '@aedifion.io/aedifion-api'
import { ProjectRoleId } from '@/stores/views/Administration/users'

/**
 * Get the project role ids from the projects
 * @param projects Projects of the company
 * @param roleIds Numeric role IDs that have to match
 * @returns Project ID and Role ID pairs
 */
export function getProjectRoleIds (projects: CompanyWithContextProjects[], roleIds: number[]): ProjectRoleId[] {
  const projectRoleIds: ProjectRoleId[] = []
  for (const project of projects) {
    for (const role of project.roles ?? []) {
      if (roleIds.includes(role.id ?? 0)) {
        projectRoleIds.push({ projectId: project.id!, roleId: role.id! })
      }
    }
  }

  return projectRoleIds
}

/**
 * Returns the role IDs from the array
 * @param projectRoleIds Array of project ID and role ID pairs
 * @returns Array of role IDs
 */
export function getRoleIds (projectRoleIds: ProjectRoleId[]): number[] {
  return projectRoleIds.map((projectRoleId) => {
    return projectRoleId.roleId
  })
}

/**
 * Checks of the passed role name is included in the set of company roles
 * @param roleName Requested role name
 * @param companyRoles Company roles
 * @returns True if the role is included in the company roles
 */
export function hasCompanyRole (roleName: string, companyRoles: CompanyRole[]): boolean {
  return companyRoles.some((companyRole) => {
    return companyRole.name === roleName
  })
}

/**
 * Checks if the role name is included in the project roles
 * @param roleName Requested role name
 * @param projectRoles Project roles
 * @param projectId Project ID, if the role should be checked for a specific project
 * @returns True if the role is included in the project roles and matched the optional project ID
 */
export function hasProjectRole (roleName: string, projectRoles: Role[], projectId?: number): boolean {
  return projectRoles.some((projectRole) => {
    if (projectId !== undefined && projectId !== projectRole.project_id) {
      return false
    }
    return projectRole.name === roleName
  })
}

/**
 * @param user The user to check.
 * @returns true if the passed user has realm admin rights, false otherwise.
 */
export function isRealmAdmin (user?: User): boolean {
  return user?.realm_role === 'admin'
}

/**
 * @param user The user to check.
 * @returns true if the passed user has company admin rights, false otherwise.
 */
export function isCompanyAdmin (user?: UserWithContext): boolean {
  return isRealmAdmin(user?.user) || hasCompanyRole('admin', user?.companyroles ?? [])
}

/**
 * @param user The user to check.
 * @param companyId (optional) The id of the specific company that the user must
 * belong to.
 * @param projectId (optional) The id of the specific project that the user must
 * be admin of.
 * @returns true if the passed user has project admin rights, false otherwise.
 */
export function isProjectAdmin (user?: UserWithContext, companyId?: number, projectId?: number): boolean {
  if (isRealmAdmin(user?.user)) {
    return true
  }
  if (companyId !== undefined && user?.company?.id !== companyId) {
    return false
  }
  return isCompanyAdmin(user) || hasProjectRole('admin', user?.roles ?? [], projectId)
}

/**
 * @param user The user to check.
 * @returns true if the passed user has access to any project in his company,
 * false otherwise.
 */
function hasCompanyWideAccess (user?: UserWithContext): boolean {
  return isCompanyAdmin(user) || (user?.companyroles ?? []).length > 0
}

/**
 * @param user The user to check.
 * @param companyId the id of the company that the user must belong to.
 * @param projectId The id of the project that the user must have access to.
 * @returns true if the passed user has access to the project, false otherwise.
 */
export function hasAccessToProject (user: UserWithContext | undefined, companyId: number, projectId: number): boolean {
  if (isRealmAdmin(user?.user)) {
    return true
  }
  if (companyId === user?.company?.id) {
    return hasCompanyWideAccess(user) || (user?.roles ?? []).some((role) => role.project_id === projectId)
  }
  return false
}

/**
 * @param user The user to check.
 * @param companyId (optional) The id of the specific company that the user must
 * not belong to.
 * @param projectId (optional) The id of the specific project that the user must
 * not have admin rights for.
 * @returns true if the passed user does not have admin rights.
 */
export function isReadOnly (user?: UserWithContext, companyId?: number, projectId?: number): boolean {
  return !isProjectAdmin(user, companyId, projectId)
}
